























































































































































































































































































































































.draggable-video-window {
    z-index: 100 !important;

    @media all and (max-width: 767px) {
        width: 100% !important;
        height: 100% !important;
        transform: none !important;
    }
}
.handles {
    position: absolute;
}
.handles-tl {
    top: -10px;
    left: -10px;
    width: 10px;
    height: 10px;
    cursor: nw-resize;
}

.handles-tm {
    top: -10px;
    left: 0;
    height: 10px;
    right: 0;
    cursor: n-resize;
}

.handles-tr {
    top: -10px;
    right: -10px;
    width: 10px;
    height: 10px;
    cursor: ne-resize;
}

.handles-ml {
    top: 0;
    bottom: 0;
    left: -10px;
    width: 10px;
    cursor: w-resize;
}

.handles-mr {
    top: 0;
    bottom: 0;
    width: 10px;
    right: -10px;
    cursor: e-resize;
}

.handles-bl {
    bottom: -10px;
    left: -10px;
    width: 10px;
    height: 10px;
    cursor: sw-resize;
}

.handles-bm {
    bottom: -10px;
    left: 0;
    right: 0;
    height: 10px;
    cursor: s-resize;
}

.handles-br {
    bottom: -10px;
    right: -10px;
    width: 10px;
    height: 10px;
    cursor: se-resize;
}
